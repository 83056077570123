import React from 'react';

import Layout from '../components/layout/layout';
import Services from "../components/services/services";

const ServicesPage = () => {
  return (
    <Layout>
      <Services />
    </Layout>
  )
}

export default ServicesPage
